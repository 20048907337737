import React from 'react'

export const extractPageSchema = (page, site) => {
  const listItems = []
  page.modules?.forEach((module, key) => {
    switch (module._type) {
      case 'marquee':
        module.items
          ?.filter((i) => !!i)
          .forEach((item, i) => {
            if (item._type === 'product') {
              if (!item.product) return
              listItems.push({
                '@type': 'ListItem',
                position: i,
                url: `${process.env.CANONICAL_URL}/products/${item.product.slug}`,
              })
            }
          })
        break
      case 'collectionGrid':
        page.products.forEach((p, i) =>
          listItems.push({
            '@type': 'ListItem',
            position: i,
            url: `${process.env.CANONICAL_URL}/products/${p.slug}`,
          })
        )
        break
    }
  })

  return [
    listItems
      ? {
          '@type': 'ItemList',
          itemListElement: listItems,
        }
      : undefined,
  ].filter((i) => !!i)
}

const generateSchema = (data) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}

export default generateSchema
